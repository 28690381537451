import { Component, OnInit, AfterViewInit, inject } from '@angular/core';
import { AuthService } from '../../servicios/auth.service';
import { Router } from '@angular/router';
import $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/servicios/config.service';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    standalone: false
})

export class NavbarComponent implements OnInit,AfterViewInit{
  private authService = inject(AuthService)
  private router = inject(Router)
  private translate = inject(TranslateService);
  configService = inject(ConfigService);
  public process;
  public user;
  private usuario=JSON.parse(sessionStorage.getItem('user'));

  constructor() {
    console.log("constructor navbar authService")
  }

  ngOnInit(){
    console.log("🚀 ~ NavbarComponent ~ ngOnInit")
    this.user=JSON.parse(sessionStorage.getItem('user'));

    this.translate.setDefaultLang(this.usuario['_locale']);
    this.configService.getProcess$().subscribe(process => {
      console.log("🚀 ~ NavbarComponent ~ ngOnInit ~ process:", process)
      this.process = process;
      Object.keys(this.process.profile).forEach(element=>{
        if(this.user._process.profile[element]!==null&&this.user._process.profile[element]!==undefined){
          this.process.profile[element]=this.user._process.profile[element];
        }
      });
    }
    )


  }

  ngAfterViewInit(){
    $("#dropdown1").mouseenter(function(){$("#lidropdown1").popover('show');}).mouseleave(function(){$("#lidropdown1").popover('hide');});
    $("#dropdown2").mouseenter(function(){$("#lidropdown2").popover('show');}).mouseleave(function(){ $("#lidropdown2").popover('hide');});

    $("#liperfil").mouseenter(function(){$("#liperfil").popover('show');}).mouseleave(function(){$("#liperfil").popover('hide');});
    $("#ordenIngeneria").mouseenter(function(){$("#ordenIngeneria").popover('show');}).mouseleave(function(){$("#ordenIngeneria").popover('hide');});
  }

  logoff() {
    sessionStorage.clear();
    this.authService.logOut();
    this.router.navigate(['']);
  }

}
