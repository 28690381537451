import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { ConfigService } from '../servicios/config.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
    readonly #configService = inject(ConfigService);

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const verificateUrl = req.url.includes('esignaturebff/selectPerfilCorreo/');

      if (verificateUrl) {
        return next.handle(req);
      }

      return from(this.#configService.getConfig()).pipe(
        switchMap(config => {
          const currentUser = JSON.parse(sessionStorage.getItem('user'));
          const token = sessionStorage.getItem('id_token');
          const endpoint = config.ENDPOINT;
          console.log("🚀 ~ BasicAuthInterceptor ~ intercept ~ endpoint:", endpoint)

          if (req.url.includes(endpoint)) {
            const modifiedReq = req.clone({
              headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'origen': 'eSignatureWEB',
                'username': currentUser._name,
                'useremail': currentUser._mail,
                'authorization': `Bearer ${token}`
              })
            });
            return next.handle(modifiedReq);
          }

          return next.handle(req);
        })
      );
    }
}
