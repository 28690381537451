import { default as swal } from 'sweetalert2';
import { PermisosService } from './../../servicios/permisos.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService, OAuthEvent } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../servicios/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from '../../servicios/message.service';
import { User } from '../../interfaces/user.service';

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css'],
    standalone: false
})

export class IndexComponent implements OnInit{

  httpOptionsAccessCors: any;
  usuarioEstado = false;
  usuarioNoAutorizado = false;
  public loading = false;

  showButton: boolean;

  public profilesAdm = [ 'eo', 'procesing', 'load', 'selectPrincipal', 'cargarArchivos', 'visitarProcesos', 'CrearCarpetas',
  'obtenerCarpetas', 'botonDescom', 'descomprimir', 'admin'];

  public profilesPub = [ 'eo', 'procesing', 'load', 'selectPrincipal', 'cargarArchivos', 'visitarProcesos', 'CrearCarpetas',
  'obtenerCarpetas', 'botonDescom', 'descomprimir'];


  public profilesLect = ['load', 'procesing', 'descomprimir', 'visitarProcesos'];

  public profilesEo = ['load', 'procesing', 'eo', 'descomprimir'];

  public profiles: any[] = [];

  constructor(private oauthService: OAuthService, private authService: AuthService
             , private router: Router, private translate: TranslateService, private mensajeria: MessageService
             , private user: User
             , public permisos: PermisosService) {

    this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
      switch (type) {
        case 'token_received':
          this.loading = true;
          this.oauthService.loadUserProfile();
          this.permisos.permisos(this.authService.identityClaims['email']).subscribe((data: any) => {
            console.log(data);
            if (data.perfilamientos === null || data.code === -1) {
              swal.fire({
                type: 'error',
                title: this.translate.instant('error_app'),
                confirmButtonText: 'OK',
                allowOutsideClick: false
              }).then((result) => {
              });
              return;
            }

            if (data.perfilamientos.length === 0 ) {
              this.usuarioNoAutorizado = true;
            }
            if (data.perfilamientos[0].estado === 0) {
               this.usuarioEstado = true;
               return;
            }
            const datos = data.perfilamientos[0].idPerfilCC;
            if (datos === 1) {
             this.profiles = this.profilesAdm;
            } else if (datos === 2) {
              this.profiles = this.profilesPub;
            } else if (datos === 3) {
              this.profiles = this.profilesLect;
            } else if (datos === 4) {
              this.profiles = this.profilesEo;
            } else {
             this.usuarioNoAutorizado = true;
              return;
            }
            this.oauthService.loadUserProfile();
          }, (error => {
            console.log('error de servicio', error);
          }));
         break;
        }
    });

    this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
      switch (type) {
        case 'user_profile_loaded':
          this.cargarUsuario();
          // this.mensajeria.loadingTransparent();
          setTimeout( ()=> this.router.navigate(['home']), 1333);
          break;
        }
    });

	this.printEnv();
    translate.setDefaultLang(translate.getBrowserLang() === undefined?"en":translate.getBrowserLang());
  }

  ngOnInit() {
    console.log(environment.ENDPOINT, environment.client_id);
  }

  printEnv(){
    //console.log(environment);
  }

  cargarUsuario() {
    this.user.name = this.authService.identityClaims['name'];
    this.user.mail = this.authService.identityClaims['email'];
    this.user.locale = this.authService.identityClaims['locale'];
    this.user.token = this.authService.accessToken;
    this.user.process = { profile: {}};
    this.profiles.forEach((element, index) => {
     // this.profilesAdm.forEach((element, index) => {

      this.user.process.profile[element] = true;

    });
    sessionStorage.setItem('user', JSON.stringify(this.user));
  }

  public login() {
    console.log(environment.ENDPOINT, environment.client_id);
    this.authService.login();
  }
}
