import { Injectable, signal } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface Config {
  api_key: string;
  client_id: string;
  environment: string;
  production: boolean;
  ENDPOINT: string;
  EmpointStorage: string;
  url_document_control: string;
  process: {
    profile: {
      admin: boolean;
      load: boolean;
      procesing: boolean;
      eo: boolean;
      descomprimir: boolean;
      selectPrincipal: boolean;
      cargarArchivos: boolean;
      visitarProcesos: boolean;
      CrearCarpetas: boolean;
      botonDescom: boolean;
      obtenerCarpetas: boolean;
    };
  };
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  readonly #configUrl = '/assets/config/app.config.json';
  readonly #config = signal<Config | null>(null);
  private loadPromise: Promise<Config> | null = null;

  async getConfig(): Promise<Config> {
    if (this.#config()) {
      return this.#config();
    }
    return await this.loadConfig();
  }

  private async loadConfig(): Promise<Config> {
    if (!this.loadPromise) {
      this.loadPromise = fetch(this.#configUrl)
        .then(response => {
          if (!response.ok) throw new Error(`HTTP error: ${response.status}`);
          return response.json();
        })
        .then(data => {
          this.#config.set(data);
          return data;
        })
        .catch(error => {
          console.error('Config load failed:', error);
          throw error;
        });
    }
    return await this.loadPromise;
  }

  getConfig$(): Observable<any> {
    return from(this.getConfig());
  }

  getEndpointWith$(path: string): Observable<string> {
    return this.getConfig$().pipe(
      map(config => `${config.ENDPOINT}${path}`)
  );
  }

  getClientId$(): Observable<string> {
    return this.getConfig$().pipe(map(config => config.client_id))
  }

  getProcess$(): Observable<Config["process"]> {
    return this.getConfig$().pipe(map(config => config.process))
  }

  getEndpointStorage$(): Observable<string> {
    return this.getConfig$().pipe(map(config => config.EmpointStorage))
  }

  getUrlDocumentControl$(): Observable<string> {
    return this.getConfig$().pipe(map(config => config.url_document_control))
  }


}
