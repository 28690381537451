import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DecompressRequest } from '../modelo/DecompressRequest';
import { ConfigService } from './config.service';
import { concatMap } from 'rxjs/operators';
import { DecompressResponse } from '../modelo/DecompressResponse';
@Injectable({
  providedIn: 'root'
})
export class ServiceDecompressService {
  configService = inject(ConfigService);
  constructor(private http: HttpClient) { }
  readonly generatedecompress = 'esignaturebff/generatedecompress';

  descomprimirService(req: DecompressRequest) {
    return this.configService.getEndpointWith$(this.generatedecompress).pipe(
      concatMap(url => this.http.post<DecompressResponse>(url, req)));
  }

}
