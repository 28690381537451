import { TecnicalFile } from './../modelo/tecnical-file.model';
import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RutaRequest } from '../modelo/RutaRequest';
import { RutaResponse } from '../modelo/RutaResponse';
import { FindFlotaRequest } from '../modelo/find-flota-request';
import { Observable ,  from } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class CarpetasService {

  configService = inject(ConfigService);
  public dic = '';
  tecnicalFiles: TecnicalFile[] = [];
  tecnicalFiles2: TecnicalFile[] = [];
  fileToUpload: any;
  httpOptionsAccessCors: any;
  // public url_file = 'http://35.231.194.3:9012/uploadFile';
  public url_file = '/esignaturebff/uploadFile';

  private readonly esignatureListPath = '/esignaturebff/esignatureListPath';
  private readonly pathDeploy = '/esignaturebff/getPathDeploy';

  constructor(private http: HttpClient) {
    this.httpOptionsAccessCors = {
      headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    };
  }



  postCarpetas(ruta: RutaRequest): Observable<RutaResponse> {
    return this.configService.getEndpointWith$(this.esignatureListPath).pipe(
      concatMap(url => this.http.post<RutaResponse>(url, ruta)));
  }


  postVersiones(flota: FindFlotaRequest): Observable<RutaResponse> {
    return this.configService.getEndpointWith$(this.pathDeploy).pipe(
      concatMap(url => this.http.post<RutaResponse>(url, flota)));
    }
  SubirArchivo(files?: FormData[]): Observable<any> {
    return this.configService.getEndpointWith$(this.url_file).pipe(
      concatMap(url =>
        from(files).pipe(concatMap(file => this.http.post(url, file, { reportProgress: true, observe: 'events' })
          )
        )
      )
    );
  }
}
