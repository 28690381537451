import { ErrorServiceService } from './../../servicios/error-service.service';
import { environment } from 'src/environments/environment';
import { RutaResponse } from './../../modelo/RutaResponse';
import { CarpetasService } from './../../servicios/carpetas.service';
import { TranslateService } from '@ngx-translate/core';
import { RutaRequest } from './../../modelo/RutaRequest';
import Swal from 'sweetalert2';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-download',
    templateUrl: './download.component.html',
    styleUrls: ['./download.component.css'],
    standalone: false
})
export class DownloadComponent implements OnInit {

  rutaDes: RutaRequest = {
    path: ''
  };

  public descarga = false;
  public process = environment.process;
  //public urlStorage = 'https://storage.cloud.google.com';
  public DownloadStorage = environment.EmpointStorage;
  public urlDocumentControl = environment.url_document_control;
  private user = JSON.parse(sessionStorage.getItem('user'));
  changeText = false;
  mensaje = false;
  louding = false;
  path1 = [];
  path2 = [];
  splitted: any;
  rutas: any[] = [];
  rutass = false;
  rutas1 = false;
  rutas2 = false;
  carpeta1 = false;
  carpeta2 = false;
  oculto = true;
  ocultoCarpeta = true;
  ocultoButom = false;
  ocultoButom1 = false;
  ocultoButom2 = false;
  ocultarFlota = false;
  segundaCarpeta: any[] = [];
  carpetas: any[] = [];
  completado = false;
  completado2 = false;
  botonRegresar1 = false;
  botonRegresar2 = false;
  public pathSourceFiles = '';
  public pathDestinationFiles = '';

  rutaRequest: RutaRequest = new RutaRequest();
  rutaResponse: RutaResponse = new RutaResponse();

  constructor(public translateService: TranslateService, public servi: CarpetasService) {
    Object.keys(this.process.profile).forEach(element => {
      if (this.user._process.profile[element] !== null && this.user._process.profile[element] !== undefined) {
        this.process.profile[element] = this.user._process.profile[element];
      }
    });
  }

  ngOnInit() {
  }

  getCarpetas() {
    this.louding = true;
    this.carpetas = [];
    this.rutaDes.path = '';
    this.completado = false;
    this.servi.postCarpetas(this.rutaDes).subscribe((data: any) => {

      this.louding = false;
      if (this.oculto) {
        this.carpetas = data.rutas;
        this.botonRegresar2 = false;
        this.botonRegresar1 = true;
        return this.carpetas;
      }

    }, (error) => {
        this.rutass = false;
        this.pathSourceFiles = '';
        this.louding = false;
       });
  }


  devolverRuta(i) {
    this.path1.splice(i);
  }

  mandarRuta(ruta: any) {
    this.completado = true;
    if (this.rutas1) {
      if (!ruta.endsWith('/')) {
        // this.rutass = false;
        // this.pathSourceFiles = '';
        return;
      }
      this.louding = true;
      if (!this.path1.includes(ruta)) {
        this.path1.push(ruta);
        this.oculto = false;
        this.ocultarFlota = true;
        this.botonRegresar1 = true;

      } else {
        Swal.fire({
          type: 'info',
          title: 'La carpeta ya esta seleccionada..',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;

      }
    }
    this.rutaDes.path = ruta;
    this.servi.postCarpetas(this.rutaDes).subscribe((data: any) => {
      this.louding = false;
      this.rutas = data.rutas;
      if (this.ocultarFlota) {
        this.pathSourceFiles = ruta;
      }
      return this.rutas;
    }, (error) => {
      this.rutass = false;
      this.pathSourceFiles = '';
      this.louding = false;
     });

  }


}
