import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImagenesService {
  configService = inject(ConfigService);
  constructor(private http: HttpClient) { }

  enviarProcesar(flota:string, amm:string, dic:string, pathVersion:string, version:string, tags?:string): Observable<any> {

    let data = {
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "flota":flota,
      "pathFilesAMM": amm,
      "pathFileDIC": dic,
      "pathVersion": pathVersion,
      "version": version,
      "toFront": true
    }
    let path: string;
    if (flota == "B787"){
      path = "esignaturegenerateb787/generate"
    }else if (flota == "B777"){
      path = "esignaturegenerateb777/generate"
    }else if (flota == "B767"){
      path = "esignaturegenerateb767/generate"
    }else if (flota == "A320"){
      path = "/esignaturegeneratea320/generate"
    }else if (flota == "A350"){
      path = "/esignaturegeneratea350/pushToEsignatureMessages"
    }
    return this.configService.getEndpointWith$(path).pipe(
          concatMap(url => {
           console.log(`REQUEST SEND.enviarProcesar.${url}${JSON.stringify(data)}`);
           return this.http.post(url, data)}));

  }
}
