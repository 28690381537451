import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../../servicios/auth.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css'],
    standalone: false
})

export class NavbarComponent implements OnInit,AfterViewInit{

  public process=environment.process;
  public user;
  private usuario=JSON.parse(sessionStorage.getItem('user'));

  constructor(private authService: AuthService,private router:Router,private translate:TranslateService) {

    this.user=JSON.parse(sessionStorage.getItem('user'));
    
    translate.setDefaultLang(this.usuario['_locale']);

    Object.keys(this.process.profile).forEach(element=>{
      if(this.user._process.profile[element]!==null&&this.user._process.profile[element]!==undefined){
        this.process.profile[element]=this.user._process.profile[element];
      }
    });
    
  }

  ngOnInit(){
  }

  ngAfterViewInit(){
    $("#dropdown1").mouseenter(function(){$("#lidropdown1").popover('show');}).mouseleave(function(){$("#lidropdown1").popover('hide');});
    $("#dropdown2").mouseenter(function(){$("#lidropdown2").popover('show');}).mouseleave(function(){ $("#lidropdown2").popover('hide');});

    $("#liperfil").mouseenter(function(){$("#liperfil").popover('show');}).mouseleave(function(){$("#liperfil").popover('hide');});
    $("#ordenIngeneria").mouseenter(function(){$("#ordenIngeneria").popover('show');}).mouseleave(function(){$("#ordenIngeneria").popover('hide');});
  }

  logoff() {
    sessionStorage.clear();
    this.authService.logOut();
    this.router.navigate(['']);
  }

}
