import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { concatMap } from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {
  configService = inject(ConfigService);
  constructor(private http: HttpClient) { }

  saveDocuments(fleet:string, dic:string, mpd:string, dtd:string, path:string): Observable<any> {

    let data = {
      "flota": fleet,
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "amm": path,
      "dic": dic,
      "mpd": mpd,
      "dtd": dtd,
      "path": path
    }

    return this.configService.getEndpointWith$("/esignaturebff/saveDocument").pipe(
      concatMap(url => {
        console.log("REQUEST SEND.getDocuments."+url, JSON.stringify(data));
        return this.http.post<any>(url, data)}));
  }
}
