import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
// import { environment } from './../../../environments/environment.dev';
import { Component, OnInit } from '@angular/core';
import { LogsService } from '../../servicios/logs.service';
import { ProcesarService } from '../../servicios/procesar.service';
import { timeout } from 'q';
import $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/servicios/message.service';
import { LocalStorageService } from 'src/app/servicios/local-storage.service';
import { RutaRequest } from 'src/app/modelo/RutaRequest';
import { RutaResponse } from 'src/app/modelo/RutaResponse';
import { ServiceDecompressService } from 'src/app/servicios/service-decompress.service';
import Swal from 'sweetalert2';
import { FindFlotaRequest } from 'src/app/modelo/find-flota-request';
import { CarpetasService } from 'src/app/servicios/carpetas.service';

@Component({
    selector: 'app-procesar',
    templateUrl: './procesar.component.html',
    styleUrls: ['./procesar.component.css'],
    standalone: false
})
export class ProcesarComponent implements OnInit {


  public process = environment.process;


  public sentObj = {
    flota: '',
    amm: '',
    dic: '',
    pathVersion: '',
    version: '',
  };
  public sentObjs = {
    flota: '',
    amm: '',
    dic: '',
    pathVersion: '',
    version: ''
  };
  public versiones: FindFlotaRequest = new FindFlotaRequest();
  version: FindFlotaRequest = {
    flota: '',
    path: ''
  };

  dataVersion: any[] = [];
  versioness = [] = [];


  rutaRequest: RutaRequest = new RutaRequest();
  rutaResponse: RutaResponse = new RutaResponse();


  public pathSourceFiles: string;
  public pathDestinationFiles: string;
  public listaStorage: string;
  rutaDes: RutaRequest = {
    path: ''
  };
  private user = JSON.parse(sessionStorage.getItem('user'));
  mensaje = false;
  changeText = false;
  listaStora = false;
  listoConfirmadoLista = false;
  listoConfirmado = false;
  listo: boolean;
  inicializacion: boolean;
  completado = false;
  completado2 = false;
  completado3 = false;
  botonRegresar1 = false;
  botonRegresar2 = false;
  botonRegresar3 = false;
  botonProceso = true;
  localStorageCompleto: any[] = [];
  noEncontrada = false;
  mensajes = false;
  dia = new Date();
  hora: number;
  minuto: number;
  segundo: any;
  contador = 0;
  RutaSiEsCarpeta: any;
  siEsCarpeta: any;
  louding = false;
  loudingImg = false;
  path1 = [];
  path2 = [];
  path3 = [];
  path4 = [];
  splitted: any;
  Rutas: any[] = [];
  rutass = false;
  rutas1 = false;
  rutas2 = false;
  rutas3 = false;
  rutas4 = false;
  carpeta1 = false;
  carpeta2 = false;
  oculto = true;
  ocultoCarpeta = true;
  ocultoButom = false;
  ocultoButom1 = false;
  ocultoButom2 = false;
  ocultoButom3 = false;
  ocultoButom4 = false;
  ocultoButom5 = false;
  ocultoButom6 = false;
  ocultarFlota = false;
  segundaCarpeta: any[] = [];
  terceraCarpeta: any[] = [];
  cuartaCarpeta: any[] = [];
  Carpeta: any[] = [];
  carpetas: any[] = [];
  public flota = '';
  public tags = undefined;
  public cargaTipo = 'completa';
  public loading = false;
  private rutas = [];

  public optionsAMM = [];

  public optionsMPD = [];

  public optionsDTD = [];

  public optionsDiccionario = [];
  rutasAmm = false;

  meses = new Array('Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
    'Noviembre', 'Diciembre');
  diasSemana = new Array('Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado');
  ddata: string = "";


  constructor(private logsService: LogsService,
    private procesarService: ProcesarService,
    private msg: MessageService,
    private translate: TranslateService,
    public servi: CarpetasService,
    public servicio: LocalStorageService, 
    public translateService: TranslateService,
    private http: HttpClient) {
    this.inicializacion = false;
    this.listo = false;
    Object.keys(this.process.profile).forEach(element => {
      if (this.user._process.profile[element] !== null && this.user._process.profile[element] !== undefined) {
        this.process.profile[element] = this.user._process.profile[element];
      }
    });
  }

  ngOnInit() {
    this.localStorageCompleto = this.servicio.nuevaListaProcesar;
    if (this.localStorageCompleto[0] === undefined) {
    } else {
      this.botonProceso = true;
    }

    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ddata = res.ip;
      console.log(this.ddata);
    });
  }

  getCarpetas() {
    this.mensajes = false;
    this.completado = false;
    this.completado2 = false;
    this.completado3 = false;
    this.servicio.completado3 = false;
    this.carpetas = [];
    this.segundaCarpeta = [];
    this.terceraCarpeta = [];
    this.cuartaCarpeta = [];
    this.louding = true;
    this.rutaDes.path = '';
    this.servi.postCarpetas(this.rutaDes).subscribe((data: any) => {
      console.log(data);

      this.louding = false;
      if (this.oculto) {
        this.carpetas = data.rutas;
        this.botonRegresar2 = false;
        this.botonRegresar1 = true;
        this.botonRegresar3 = false;
        return this.carpetas;
        // los guarda en el segundo
      } else if (this.ocultoButom1) {
        this.segundaCarpeta = data.rutas;
        this.botonRegresar1 = false;
        this.botonRegresar2 = true;
        this.botonRegresar3 = false;

        return this.segundaCarpeta;
      } else if (this.ocultoButom3) {
        this.completado = false;
        this.completado2 = false;
        this.completado3 = true;
        this.terceraCarpeta = data.rutas;
        this.botonRegresar1 = false;
        this.botonRegresar2 = false;
        this.botonRegresar3 = true;
        return this.terceraCarpeta;
      } else if (this.ocultoButom5) {
        this.cuartaCarpeta = data.rutas;

        return this.cuartaCarpeta;
      }
    }, (error) => {
      this.rutass = false;
      this.louding = false;
      this.completado = false;
      this.completado2 = false;
      this.completado3 = false;
     });
  }

  devolverRuta(i: number) {
    this.path1.splice(i);
    this.path2.splice(i);
    this.path3.splice(i);
    this.path4.splice(i);
  }

  mandarRuta(ruta: any) {
    console.log(ruta);
    this.siEsCarpeta = [];

    if (this.rutas1) {
      this.completado = true;

      if (!ruta.endsWith('/')) {
        Swal.fire({
          type: 'error',
          title: this.translateService.instant('solo_carpetas'),
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;
      }

      this.Rutas = [];
      if (!this.path1.includes(ruta)) {
        this.oculto = false;
        this.louding = true;
        this.siEsCarpeta = ruta;
        this.path1.push(this.siEsCarpeta);
        this.botonRegresar1 = true;
        this.completado2 = false;
        this.completado = true;
        this.completado3 = false;


      } else {
        Swal.fire({
          type: 'info',
          title: 'La carpeta ya esta seleccionada..',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;
      }
    }


    if (this.rutas2) {
      this.Rutas = [];
      this.louding = true;
      if (!this.path2.includes(ruta)) {
        this.path2.push(ruta);
        this.botonRegresar2 = true;
        this.completado = false;
        this.completado2 = true;
        this.completado3 = false;

      } else {
        Swal.fire({
          type: 'info',
          title: 'La carpeta ya esta seleccionada..',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;
      }
    }

    if (this.rutas3) {
      if (!ruta.endsWith('/')) {
        Swal.fire({
          type: 'error',
          title: this.translateService.instant('solo_carpetas'),
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;
      }

      this.Rutas = [];
      if (!this.path3.includes(ruta)) {
        this.louding = true;
        this.path3.push(ruta);
        this.botonRegresar3 = true;
        this.ocultoButom3 = false;
        this.ocultoButom4 = true;
      } else {
        Swal.fire({
          type: 'info',
          title: 'La carpeta ya esta seleccionada..',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;
      }
    }

    this.rutaDes.path = ruta;

    this.servi.postCarpetas(this.rutaDes).subscribe((data: any) => {
      this.louding = false;
      this.Rutas = data.rutas;
      this.RutaSiEsCarpeta = data.rutas;

      if (this.ocultarFlota) {
        this.sentObj.amm = this.siEsCarpeta;

      } else if (this.ocultoButom2) {

        this.sentObj.dic = ruta;

      } else if (this.ocultoButom4) {
        this.listaStorage = ruta;

      } else if (this.ocultoButom6) {
        this.sentObj.version = ruta;

      }
      return this.Rutas;
    }, (error) => {
      this.rutass = false;
      this.sentObj.amm = '';
      this.sentObj.dic = '';
      this.listaStorage = '';
      this.sentObj.version = '';
      this.louding = false;
      this.completado = false;
      this.completado2 = false;
      this.completado3 = false;
     });
  }

  rutaNueva(flota: string, ruta: string) {

    this.loudingImg = true;
    this.version.flota = flota;
    this.version.path = ruta;
    this.servi.postVersiones(this.version).subscribe((data: any) => {
      this.loudingImg = false;
      if (this.version.flota !== 'B787' && data.versiones[0] === '' || data.versiones[0] === 'version') {
        this.noEncontrada = true;
        return;
      }

      this.versioness = data.rutas;
      this.dataVersion = data.versiones;
      this.contador = 0;
      this.noEncontrada = false;
      return this.dataVersion;
    }, (error) => {
      this.loudingImg = false;
      this.rutasAmm = false;
    });

  }

  noEncontradas() {
    if (this.versioness.length === 0) {
      this.noEncontrada = true;
    }

  }

  versionSola() {
    if (this.sentObj.pathVersion === '') {
      this.sentObj.pathVersion = 'version';
    } else {

      const nuevaVersion = this.sentObj.pathVersion,
        nuevoArray = nuevaVersion.split('/'),
        ultima = nuevoArray[nuevoArray.length - 2];
      if (this.dataVersion[0] === '' || this.flota === 'B787') {
        this.sentObj.version = this.dataVersion[0];
        return;
      } else {
        this.sentObj.version = ultima;
      }
    }

  }

  submit(form) {
    if (!this.sentObj.dic.endsWith('.xlsx')) {
      Swal.fire({
        type: 'error',
        title: this.translateService.instant('solo_xls'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.louding = false;
      });
      this.completado = false;
      this.completado2 = false;
      this.mensajes = false;
      this.sentObj.dic = '';
      return;
    }
    if (this.sentObj.flota === '' || this.sentObj.amm === '' || this.sentObj.dic === ''
      || this.sentObj.pathVersion === '' || this.sentObj.pathVersion === 'version') {

      Swal.fire({
        type: 'error',
        title: this.translateService.instant('todos_campos'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.louding = false;
      });
      this.completado = false;
      this.completado2 = false;
      this.mensajes = false;
      return;
    } else {
      this.completado3 = false;
      const today = new Date();
      this.tags = this.cargaTipo === 'incremental' ? this.tags : undefined;
      this.loading = true;
      this.procesarService
        .enviarProcesar(
          this.sentObj.flota,
          this.sentObj.amm,
          this.sentObj.dic,
          this.sentObj.pathVersion,
          this.sentObj.version,
          this.tags
        )
        .subscribe(
          response => {
            this.loading = false;
            this.completado = false;
            this.completado2 = false;
            this.guardarDatos();
            this.inicializacion = false;
            this.msg.mensajeCustom([
              'mensaje_procesar_exito_1',
              this.flota,
              'mensaje_procesar_exito_2',
              JSON.parse(sessionStorage.getItem('user'))._name,
              'mensaje_procesar_exito_3'
            ]);
            this.clean();
            this.listoConfirmado = false;
            this.mensajes = true;
            this.botonRegresar1 = false;
            this.botonRegresar2 = false;
            this.logsService.addLog({
              fecha: today.toString(),
              tipo: 'Carga de Archivos',
              flota: this.sentObj.flota,
              status: 'Exitosa',
              detalle: ['B767', 'B777', 'B787', 'A320', 'A350'].includes(
                this.sentObj.flota
              )
                ? 'AMM+DIC+ATCT'
                : 'AMM+DIC',
              usuario: '@' + JSON.parse(sessionStorage.getItem('user'))._name
            });
          },
          error => {
            this.loading = false;
            this.listoConfirmado = false;
            this.logsService.addLog({
              fecha: today.toString(),
              tipo: 'Carga de Archivos',
              flota: this.sentObj.flota,
              status: 'Fallido',
              detalle: ['B767', 'B777', 'B787', 'A320', 'A350'].includes(
                this.sentObj.flota
              )
                ? 'AMM+DIC+ATCT'
                : 'AMM+DIC',
              usuario: '@' + JSON.parse(sessionStorage.getItem('user'))._name
            });

          }
        );
    }
  }

  stilo() {
    this.sentObj.flota = this.flota;
    this.procesarService.getDocuments(this.flota).subscribe(response => {
      this.optionsDiccionario = response.publicationsDIC;
      this.optionsAMM = response.publicationsAMM;
      this.optionsMPD = response.publicationsMPD;
      this.optionsDTD = response.publicationsDTD;
    });
    $(".form-group.col-md-6.d-flex").css("left", "4.3%");
  }

  public select(item, list, element, index) {
    $("a.row[name^='" + element + "_option']").css("background", "none");
    $("a.row[name='" + element + "_option_" + index + "']").css(
      "background",
      "blue"
    );
    this.sentObj[element] = list[index];
  }

  public clean() {
    $("a.row[name^='dic_option']").css("background", "none");
    $("a.row[name^='amm_option']").css("background", "none");
    $("a.row[name^='mpd_option']").css("background", "none");
    $("a.row[name^='dtd_option']").css("background", "none");
    this.optionsDiccionario = null;
    this.optionsAMM = null;
    this.optionsMPD = null;
    this.optionsDTD = null;

    this.sentObjs = {
      flota: this.sentObj.flota,
      amm: this.sentObj.amm,
      dic: this.sentObj.dic,
      pathVersion: this.sentObj.pathVersion,
      version: this.sentObj.version
    };
    return this.sentObjs;
  }

  mueveReloj() {
    this.dia = new Date();
    this.hora = this.dia.getHours();
    this.minuto = this.dia.getMinutes();
    this.segundo = this.dia.getSeconds();

    this.hora = this.colocar0(this.hora);
    this.minuto = this.colocar0(this.minuto);
    this.segundo = this.colocar0(this.segundo);
    setTimeout('mueveReloj()', 1000);

  }

  colocar0(i: any) {

    if (i < 10) { i = '0' + i; } // Añadir el cero en números menores de 10

    return i;

  }

  guardarDatos() {

    this.servicio.saveDocumentsPro(
      this.sentObj.flota,
      this.sentObj.amm,
      this.sentObj.dic,
      this.sentObj.pathVersion,
      this.sentObj.version
    );
    this.botonProceso = true;

  }

  verProcesos() {
    this.servicio.procesosP = true;
    const data = 'procesar';
    this.servicio.consultarProcesos(data);
  }

  OcultarProcesos() {
    this.servicio.procesosP = false;
  }

  listado() {
    if (!this.sentObj.dic.endsWith('.xlsx')) {
      Swal.fire({
        type: 'error',
        title: this.translateService.instant('solo_xls'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.louding = false;
      });
      this.completado = false;
      this.completado2 = false;
      this.mensajes = false;
      this.sentObj.dic = '';
      return;
    }
    const data = {
 user: JSON.parse(sessionStorage.getItem('user'))._mail,
 flota: this.sentObj.flota,
 pathFilesAMM:  this.sentObj.amm,
 pathFileDIC:  this.sentObj.dic,
 pathVersion:  this.sentObj.pathVersion,
 version:  this.sentObj.version,
 pathSavedList:  this.listaStorage
};
if (data.flota === '' || data.pathFilesAMM === '' || data.pathFileDIC === '' || data.pathVersion === ''
    || data.pathSavedList === '') {
      Swal.fire({
        type: 'error',
        title: this.translateService.instant('campos_vacios'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
      });
      return;
} else {
  this.loading = true;
this.servicio.generaListado(data).subscribe((resp: any) => {
  this.loading = false;
  this.listoConfirmadoLista = false;
  Swal.fire({
    type: 'success',
    title: this.translateService.instant('notificacion' ) + ' ' + this.user._mail + ' ' + this.translateService.instant('completado'),
    confirmButtonText: 'OK',
    allowOutsideClick: false
  }).then((result) => {
  });
  return;
}, (error: HttpErrorResponse) => {
  this.loading = false;
  this.listoConfirmadoLista = false;
});

}

  }

  abrirModal() {

    Swal.fire({
      title: this.translateService.instant('crear_carpeta'),
      input: 'text',
      inputValue: this.ddata,
      showCancelButton: true,
    }
    ).then((result) => {
      if (result.value) {
        this.listoConfirmadoLista = true;
        if (!result.value.endsWith('/')) {
          result.value = result.value + '/';
        }
        this.listaStorage = this.listaStorage + result.value;
      }
    });
  }

}
