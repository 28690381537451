import { Orders } from './../modelo/order';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EDocService {
    configService = inject(ConfigService);
   urlTally = 'esignaturebff/order-works-tally-date-dtOrden';
   urlDate = 'esignaturebff/order-works-date-dtOrden';
   urlRFI = 'esignaturebff/order-rfi-tally-date-dtOrden';
   urlProcessBarcodes = 'esignaturebff/process-barcodes';
   urlValidateBarcodeList = 'esignaturebff/validate-barcodes';
   urlProcessBarcodeList = 'esignaturebff/process-barcodes';

  constructor(private http: HttpClient) { }

  consultaDate(orden: Orders) {
    return this.configService.getEndpointWith$(this.urlDate).pipe(
      concatMap(url => this.http.post(url, orden)));
  }

  consultaTally(orden: Orders) {
    return this.configService.getEndpointWith$(this.urlTally).pipe(
      concatMap(url => this.http.post(url, orden)));
  }

  consultaRFI(orden: Orders) {
    return this.configService.getEndpointWith$(this.urlRFI).pipe(
      concatMap(url => this.http.post(url, orden)));
  }

  procesarBarcodes(barcodes : any[]) {
    let data = {
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "barcodes": barcodes
    }
    return this.configService.getEndpointWith$(this.urlProcessBarcodes).pipe(
      concatMap(url => this.http.post(url, data)));
  }

  validateBarcodeList(barcodes: any[]){
    console.log(barcodes);

    var vals = barcodes.map(function(a) {return {barcode: a.barcode,type: a.type };});
    // console.log(vals);
    let data = {
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "barcodes": barcodes
    }
    return this.configService.getEndpointWith$(this.urlValidateBarcodeList).pipe(
      concatMap(url => this.http.post(url, data)));
  }

  sendBarcodesList(barcodesList:any[]){

    let data = {
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "barcodes": barcodesList
    }

    console.log(data);
    return this.configService.getEndpointWith$(this.urlProcessBarcodeList).pipe(
      concatMap(url => this.http.post(url, data)));

  }

}
