import { AuthConfig } from 'angular-oauth2-oidc';

export const getAuthConfig = (client_id: string): AuthConfig => ({

  // Url of the Identity Provider
  issuer: 'https://accounts.google.com',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin,

  strictDiscoveryDocumentValidation: false,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  clientId: client_id,

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email https://www.googleapis.com/auth/cloud-platform https://www.googleapis.com/auth/admin.directory.group.readonly',

  customQueryParams: {'prompt': 'select_account','cookie_policy':'single_host_origin'},

  showDebugInformation: true
});
