import { TranslateService } from '@ngx-translate/core';
import { PermisosService } from './../../servicios/permisos.service';
import { LocalStorageService } from './../../servicios/local-storage.service';
import { NgForm } from '@angular/forms';
import { TecnicalFile } from './../../modelo/tecnical-file.model';
import { HttpClient, HttpHeaders, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { CarpetasService } from './../../servicios/carpetas.service';
import { RutaRequest } from './../../modelo/RutaRequest';
import { Component, OnInit, AfterViewInit, inject } from '@angular/core';
import { MessageService } from '../../servicios/message.service';
import { LogsService } from '../../servicios/logs.service';
import swal from 'sweetalert2';

import { DocumentationService } from 'src/app/servicios/documentation.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfigService } from 'src/app/servicios/config.service';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: false
})

export class HomeComponent implements OnInit, AfterViewInit {

  httpOptionsAccessCors: any;
  configService = inject(ConfigService);

  rutaDes: RutaRequest = {
    path: ''

  };
  cancelarllegada: Subscription;
  cuentaFallo = 0;
  fallo = false;
  contador = 0;
  comparar: any;
  pendingProcess: FormData[] = [];
  nuevaEnviar: any[] = [];
  botonProceso = true;
  private ngUnsubscribe = new Subject();
  botonCancelar = false;
  formulario: NgForm;
  cancelar: Subscription;
  cargando = true;
  terminoProceso = false;
  listoConfirmado = true;
  carga = true;
  error: boolean;
  terminoCarga = false;
  acepto = false;
  public respuestaImagenEnviada;
  public resultadoCarga;
  enviarServi: FormData[] = [];
  progress = { loaded: 0, total: 0 };
  file = 0;
  progres = false;
  correr = false;
  formData: any;
  inicial = 0;
  imagenSubir: File;
  nuevoArchivo: any;
  nuevoArchivo2: any;
  archivosRecorridos: any[] = [];
  archivoCargar: FileList[] = [];
  tecnicalFiles: TecnicalFile[] = [];
  tecnicalFiles2: TecnicalFile[] = [];
  louding = false;
  path1 = [];
  path2 = [];
  splitted: any;
  rutas: any[] = [];
  rutass = false;
  rutas1 = false;
  rutas2 = false;
  carpeta1 = false;
  carpeta2 = false;
  oculto = true;
  ocultoCarpeta = true;
  ocultoButom = false;
  ocultoButom1 = false;
  ocultoButom2 = false;
  ocultarFlota = false;
  segundaCarpeta: any[] = [];
  carpetas: any[] = [];
  fileToUpload: any;
  fileToUpload2: any;

  private user = JSON.parse(sessionStorage.getItem('user'));
  localStorageCompleto: any[] = [];
  mensaje = false;
  mensajes = false
  dia = new Date();
  hora: any;
  minuto: any;
  segundo: any;

  meses = new Array('Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
    'Noviembre', 'Diciembre');
  diasSemana = new Array('Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado');
  completado = false;
  botonRegresar1 = false;
  botonRegresar2 = false;
  public flota = '';
  public amm: '';

  public carpetaDestino = {
    dic: ''
  };

  public enviar = {
    ruta: ''

  };
  tecnicalFilesName: any[] = [];
  public nombre: any[] = [];
  repetir: any[] = [];
  public mpd: '';
  public dtd: '';
  public path: '';
  public logs = [];
  public process;
  public cargar = true;
  ddata: string = "";

  constructor(private mensajeria: MessageService, public servi: CarpetasService,
    private logsService: LogsService, private documents: DocumentationService,
    public http: HttpClient, public service: LocalStorageService, public permi: PermisosService,
    private translateService: TranslateService
    ) {
    this.httpOptionsAccessCors = {
      headers: new HttpHeaders().set('Access-Control-Allow-Origin', '*')
    };



  }

  getCarpetas() {
    this.louding = true;
    this.carpetas = [];
    this.segundaCarpeta = [];
    this.rutaDes.path = '';
    this.completado = false;
    this.botonRegresar1 = true;

    this.servi.postCarpetas(this.rutaDes).subscribe((data: any) => {
      this.louding = false;
      if (this.oculto) {
        this.carpetas = data.rutas;
        return this.carpetas;
      } else if (this.ocultoButom1) {
        this.segundaCarpeta = data.rutas;
        return this.segundaCarpeta;
      }
    }, (error) => {
      this.rutass = false;
      this.louding = false;
     });
  }

  mueveReloj() {
    this.dia = new Date();
    this.hora = this.dia.getHours();
    this.minuto = this.dia.getMinutes();
    this.segundo = this.dia.getSeconds();
    this.hora = this.colocar0(this.hora);
    this.minuto = this.colocar0(this.minuto);
    this.segundo = this.colocar0(this.segundo);
    setTimeout('mueveReloj()', 1000);
  }

  colocar0(i: any) {

    if (i < 10) { i = '0' + i; } // Añadir el cero en números menores de 10
    return i;
  }

  devolverRuta(i) {
    this.path1.splice(i);
    this.path2.splice(i);
  }

  mandarRuta(ruta: any) {
    this.completado = true;
    if (!ruta.endsWith('/')) {
      swal.fire({
        type: 'error',
        title: this.translateService.instant('solo_carpetas'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.louding = false;
      });
      return;
    }
    this.rutas = [];

    if (this.rutas1) {
      this.louding = true;
      if (!this.path1.includes(ruta)) {
        this.path1.push(ruta);
        this.ocultarFlota = true;
        this.botonRegresar1 = true;
      } else {
        swal.fire({
          type: 'info',
          title: 'La carpeta ya esta seleccionada..',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;
      }
    }

    if (this.rutas2) {
      this.louding = true;
      if (!this.path2.includes(ruta)) {
        this.path2.push(ruta);
        this.botonRegresar2 = true;
      } else {
        swal.fire({
          type: 'info',
          title: 'La carpeta ya esta seleccionada..',
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
          this.louding = false;
        });
        return;

      }
    }

    this.rutaDes.path = ruta;

    this.servi.postCarpetas(this.rutaDes).subscribe((data: any) => {
      this.louding = false;
      this.rutas = data.rutas;
      if (this.ocultarFlota) {
        this.carpetaDestino.dic = ruta;
      } else if (this.ocultoButom2) {
        this.path = ruta;
      }
      return this.rutas;
    }, (error) => {
      this.rutass = false;
      this.louding = false;
      this.rutas = [];
     });
  }


  public onArchivoSeleccionado1($event) {
    if ($event.target.files.length !== 0) {
      this.carga = true;
      this.inicial = 0;
      for (let i = 0; i < $event.target.files.length; i++) {
        this.nuevoArchivo = $event.target.files.item(i);
        const tecnicalFile = new TecnicalFile(this.nuevoArchivo, 0, i, this.nuevoArchivo.name, false, false, false, false,
          JSON.parse(sessionStorage.getItem('user'))._name);
        this.tecnicalFiles.push(tecnicalFile);
      }
    }
  }


  public procesarArchivo(files: TecnicalFile[]) {
    if (this.flota === '') {
      swal.fire({
        type: 'error',
        title: this.translateService.instant('debe_seleccionar_flota'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.louding = false;
      });
      return;
    }
    if (this.carpetaDestino.dic === '') {
      swal.fire({
        type: 'error',
        title: this.translateService.instant('debe_seleccionar_ruta'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.louding = false;
      });
      return;
    }

    if (files.length === 0) {
      swal.fire({
        type: 'error',
        title: this.translateService.instant('debe_seleccionar_archivo'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
        this.louding = false;
      });
      return;
    } else {

      if (!this.carpetaDestino.dic.endsWith('/')) {
        this.carpetaDestino.dic = this.carpetaDestino.dic + '/';
      }
      this.rutass = false;
      this.completado = false;
      this.enviar.ruta = this.carpetaDestino.dic;
      this.botonCancelar = true;
      this.progres = true;
      this.cargando = false;
      this.listoConfirmado = false;
      this.file = files.length;
      for (let i = 0; i < files.length; i++) {
        this.formData = new FormData();
        this.tecnicalFiles[i].id = i;
        this.fileToUpload = files[i].tecnicalFile;
        this.tecnicalFilesName.push(this.fileToUpload.name);
        this.formData.append('file', this.fileToUpload, this.fileToUpload.name);
        this.formData.append('ruta', this.carpetaDestino.dic);
        this.formData.append('flota', this.flota);
        this.formData.append('date', new Date().toString());
        this.enviarServi.push(this.formData);
      }
    }
    this.uploadArchivo(this.enviarServi);
  }

  uploadArchivo(formData: FormData[]) {
    this.terminoProceso = false;
    // le asigno esta variable para luego poder un unSubscribe para el boton de cancelar
    this.cancelar = this.servi.SubirArchivo(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((dato: any) => {
      this.carga = false;
      if (dato.type === HttpEventType.UploadProgress) {
        this.tecnicalFiles[this.inicial].progress = Math.round(dato.loaded / dato.total * 100);
        this.tecnicalFiles[this.inicial].acepto = true;
      } if (dato.type === HttpEventType.Response) {
        if (dato.body.message === 'Upload') {
          this.tecnicalFiles[this.inicial].finalizado = true;
          this.tecnicalFiles[this.inicial].progress = 0;
          this.tecnicalFiles[this.inicial].acepto = false;

        } else {
          this.tecnicalFiles[this.inicial].progress = 0;
          this.tecnicalFiles[this.inicial].acepto = false;
        }
        this.inicial++;
        this.cuentaFallo++;
      }
      if (this.inicial === this.file) {

        this.guardarDatos();
        this.enviarServi = [];
        formData = [];
        if (this.nombre.length < 1) {
          this.terminoProceso = true;
          } else {
            this.terminoProceso = false;
          }
        this.progres = false;
        this.cuentaFallo = 0;
        this.botonCancelar = false;
        this.flota = '';
      }
    }, (err: HttpErrorResponse) => {
      this.nombre.push(this.tecnicalFiles[this.inicial].name);
      this.repetir.push(0);
      this.tecnicalFiles[this.inicial].error = true;
      this.tecnicalFiles[this.inicial].progress = 0;
      this.tecnicalFiles[this.inicial].acepto = false;
      this.consultaLlegada(this.nombre);
      if (formData.length > 1) {
        this.cuentaFallo++;
        this.pendingProcess = formData.splice(0, this.cuentaFallo);
        this.cuentaFallo = 0;
        this.inicial++;
        this.uploadArchivo(formData);
      } else {
        this.guardarDatos();
        this.enviarServi = [];
        formData = [];
        this.cuentaFallo = 0;
        if (this.nombre.length < 1) {
          this.terminoProceso = true;
          } else {
            this.terminoProceso = false;
          }
        this.progres = false;
        this.botonCancelar = false;
        this.flota = '';
        return;
      }
    });
  }

  consultaLlegada(nombre: any[]) {
    setTimeout(() => {
      this.rutaDes.path = this.enviar.ruta;
     this.cancelarllegada = this.servi.postCarpetas(this.rutaDes).pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
        const datos = data;
        for (let i = 0; i < nombre.length; i++) {
          this.comparar = this.rutaDes.path + nombre[i];
          const index = datos.rutas.findIndex(nom => nom === this.comparar);
          const index2 = this.tecnicalFilesName.findIndex(nomb => nomb === nombre[i]);
          this.repetir[i]++;
          if (index > -1) {

            swal.fire({
              type: 'success',
              title: this.translateService.instant('el_archivo') + nombre[i] + this.translateService.instant('carga_exitosamente'),
              width: 900
            });
            if (index2 > -1) {
              nombre.splice(i, 1);
              this.repetir.splice(i, 1);
              this.tecnicalFiles[index2].error = false;
              this.tecnicalFiles[index2].finalizado = true;
              if (this.nombre.length < 1) {
                this.tecnicalFilesName = [];
                this.botonCancelar = false;
              this.cancelarllegada.unsubscribe();
              this.terminoProceso = true;
              } else {
                this.terminoProceso = false;
              }
              this.consultaLlegada(nombre);
              return;
            }
          }
          if (this.repetir[i] < 22) {
            this.consultaLlegada(nombre);
          } else {
            swal.fire({
              type: 'error',
              title: this.translateService.instant('el_archivo') + nombre[i] + this.translateService.instant('no_carga'),
              width: 900
            });
            this.nombre.splice(i, 1);
            this.repetir.splice(i, 1);
            this.tecnicalFiles[index2].error = false;
            this.tecnicalFiles[index2].fallo = true;
            this.terminoProceso = true;
            this.botonCancelar = false;
          }
        }
      });
    }, 60000);
  }


  eliminarArchivo(name: any) {
    for (let i = 0; i < this.tecnicalFiles.length; i++) {
      if (this.tecnicalFiles[i].name === name) {

        swal.fire({
          type: 'info',
          title: this.translateService.instant('seguro'),
          text: this.translateService.instant('quiere_eliminar') + ` ${this.tecnicalFiles[i].name} ` +
            this.translateService.instant('de_la_lista'),
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.translateService.instant('si_eliminar')
        }).then((result) => {
          if (result.value) {
            this.tecnicalFiles.splice(i, 1);
            swal.fire(
              this.translateService.instant('eliminado'),
              this.translateService.instant('eliminado_correctamente'),
              'success'
            );
            return this.tecnicalFiles;
          }
        });
      }
    }

  }


  guardarDatos() {
    this.nuevaEnviar = [];
    for (let i = 0; i < this.tecnicalFiles.length; i++) {
      this.nuevaEnviar.push(this.tecnicalFiles[i].name);
    }

    if (this.flota === '' || this.carpetaDestino.dic === '' || this.nuevaEnviar.length === 0) {
      return;
    } else {
      this.service.saveDocumentsUpload(
        this.flota,
        this.carpetaDestino.dic,
        this.nuevaEnviar
      );
      this.mensaje = true;
      this.localStorageCompleto = this.service.nuevaListaCargar;
      this.botonProceso = true;
    }
  }


  ngAfterViewInit() {
    this.mensajeria.close();
  }

  ngOnInit() {
    this.logs = this.logsService.getLog();
    this.localStorageCompleto = this.service.nuevaListaCargar;
    if (this.localStorageCompleto[0] === undefined) {
    } else {
      this.botonProceso = true;
    }

    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ddata = res.ip;
      console.log(this.ddata);
    });

    this.configService.getProcess$().subscribe(process => {
      this.process = process;
    Object.keys(this.process.profile).forEach(element => {
      if(this.user){
        if (this.user._process.profile[element] !== null && this.user._process.profile[element] !== undefined) {
          this.process.profile[element] = this.user._process.profile[element];
        }
      }
      else{
        window.location.href = '/';
      }
    });
    })

  }

  submit(form: NgForm) {
    this.formulario = form;
  }

  OnDestroy() {
    console.log('si se llamo');
    swal.fire({
      type: 'info',
      title: this.translateService.instant('seguro'),
      // text: this.translateService.instant('cancelar_operacion'),
      text: 'cancelar_operacion',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.translateService.instant('si_cancelar'),

    }).then((result) => {
      if (result.value) {

        swal.fire({
          position: 'center',
          type: 'success',
          title: this.translateService.instant('operacion_cancelada'),
          showConfirmButton: false,
          timer: 1500
        });
        this.listoConfirmado = true;
        // this.cancelar.unsubscribe();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.flota = '';
        this.formData = [];
        this.tecnicalFiles = [];
        this.enviarServi = [];
        this.cargando = true;
        this.terminoProceso = true;
        this.listoConfirmado = true;
        this.inicial = 0;
        this.carpetaDestino.dic = '';
        this.progres = false;
        this.botonCancelar = false;
        this.rutass = false;
      }
    });
  }

  verProcesos() {
    this.service.procesos = true;
    const data = 'Upload';
    this.service.consultarProcesos(data);
  }

  OcultarProcesos() {
    this.service.procesos = false;
  }

  abrirModal() {

    swal.fire({
      title: this.translateService.instant('crear_carpeta'),
      input: 'text',
      inputValue: this.ddata,
      showCancelButton: true,
    }
    ).then((result) => {
      if (result.value) {
        if (!result.value.endsWith('/')) {
          result.value = result.value + '/';
        }
        this.carpetaDestino.dic = this.carpetaDestino.dic + result.value;
      }
    });
  }
}
