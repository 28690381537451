import { Component } from '@angular/core';

import { Router } from '@angular/router';
import { OAuthService, OAuthEvent } from 'angular-oauth2-oidc';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent {
  title = 'eSignature';


  constructor(private oauthService:OAuthService,private route: Router,private translate:TranslateService){

    this.oauthService.events.subscribe(({ type }: OAuthEvent) => {
      switch (type) {
        case 'token_expires':
            swal.fire({
              title:this.translate.instant('sesion_expirada')
            });
            sessionStorage.clear();
            route.navigate(['']);
          break;
        }
    });

  }

}
