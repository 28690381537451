import { Component, OnInit, AfterViewInit } from '@angular/core';
import $ from 'jquery';
import 'bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: false
})
export class FooterComponent implements OnInit, AfterViewInit {

  private usuario = JSON.parse(sessionStorage.getItem('user'));

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $("span.flag-icon.flag-icon-es").mouseenter(function() {
      $("span.flag-icon.flag-icon-es").popover('show');
    }).mouseleave(function() {
      $("span.flag-icon.flag-icon-es").popover('hide');
    }).click(function() {
      $("span.flag-icon.flag-icon-es").popover('hide');
    });

    $("span.flag-icon.flag-icon-gb").mouseenter(function() {
      $("span.flag-icon.flag-icon-gb").popover('show');
    }).mouseleave(function() {
      $("span.flag-icon.flag-icon-gb").popover('hide');
    }).click(function() {
      $("span.flag-icon.flag-icon-gb").popover('hide');
    });

    $("span.flag-icon.flag-icon-pt").mouseenter(function() {
      $("span.flag-icon.flag-icon-pt").popover('show');
    }).mouseleave(function() {
      $("span.flag-icon.flag-icon-pt").popover('hide');
    }).click(function() {
      $("span.flag-icon.flag-icon-pt").popover('hide');
    });
  }

  switchLanguage(language: string) {
    this.usuario['_locale'] = language;
    sessionStorage.setItem('user', JSON.stringify(this.usuario));
    this.translate.use(language);
  }


}
