import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { ConfigService } from './config.service';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProcesarService {
  configService = inject(ConfigService);
  constructor(private http: HttpClient) { }


  getDocuments(fleet:string):Observable<any>{
    let data = {
      "flota": fleet
    }

    return this.configService.getEndpointWith$("esignaturebff/findPublication").pipe(
      concatMap(url => {
      console.log(`REQUEST SEND.getDocuments.${url}${JSON.stringify(data)}`);
      return this.http.post(url, data)}));
  };

  enviarProcesar(flota:string, amm:string, dic:string, pathVersion:string, version:string, tags?:string): Observable<any> {

    let data = {
      "user": JSON.parse(sessionStorage.getItem('user'))._mail,
      "flota":flota,
      "pathFilesAMM": amm,
      "pathFileDIC": dic,
      "pathVersion": pathVersion,
      "version": version
    }
    let path: string;
    if (flota == "B787"){
      path = "/esignaturebff/generateFlota787";
    }else if (flota == "B777"){
      path = "/esignaturebff/generateFlota777";
    }else if (flota == "B767"){
      path = "/esignaturebff/generateFlota767";
    }else if (flota == "A320"){
      path = "/esignaturebff/generateFlota320";
    }else if (flota == "A350"){
      path = "/esignaturebff/generateFlota350";
    }

    return this.configService.getEndpointWith$(path).pipe(
          concatMap(url => {
          console.log(`REQUEST SEND.enviarProcesar.${url}${JSON.stringify(data)}`);
          return this.http.post(url, data)}));
  }
}
