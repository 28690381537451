import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http'
import { TranslateModule,TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CKEditorModule } from 'ckeditor4-angular';

import { User } from './interfaces/user.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



import { AppComponent } from './app.component';
import { IndexComponent } from './componentes/index/index.component';
import { AuthService } from './servicios/auth.service';
import { MessageService } from './servicios/message.service';
import { HomeComponent } from './componentes/home/home.component';
import { NavbarComponent } from './componentes/navbar/navbar.component';
import { FooterComponent } from './componentes/footer/footer.component';
import { EoComponent } from './componentes/eo/eo.component';
import { PerfilComponent } from './componentes/perfil/perfil.component';
import { ProcesarComponent } from './componentes/procesar/procesar.component';
import { ImagenesComponent } from './componentes/imagenes/imagenes.component';
import { BasicAuthInterceptor } from './interceptor/basicAuth.interceptor';
import { EngineeringOrderService } from './servicios/engineeringOrder.service';
import { BuscarComponent } from './componentes/buscar/buscar.component';
import { DocumentationService } from './servicios/documentation.service';
import { DescomprimirComponent } from './componentes/descomprimir/descomprimir.component';
import { SplitPipePipe } from './pipes/split-pipe.pipe';
import { ErroresInterceptor } from './interceptor/errores.interceptor';
import { DownloadComponent } from './componentes/download/download.component';
import { EDocComponent } from './componentes/e-doc/e-doc.component';
import {MatButtonModule} from '@angular/material/button';
import { MaterialModule } from './material/material.module';

@NgModule({ declarations: [
        AppComponent,
        IndexComponent,
        HomeComponent,
        NavbarComponent,
        FooterComponent,
        EoComponent,
        PerfilComponent,
        ProcesarComponent,
        ImagenesComponent,
        BuscarComponent,
        DescomprimirComponent,
        SplitPipePipe,
        DownloadComponent,
        EDocComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        CKEditorModule,
        FormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        // NgxLoadingModule.forRoot({
        //   fullScreenBackdrop: true
        // }),
        OAuthModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MatButtonModule,
        MaterialModule
        // QuillModule.forRoot(),
    ], providers: [
        AuthService,
        MessageService,
        User,
        DocumentationService,
        EngineeringOrderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BasicAuthInterceptor,
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErroresInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
