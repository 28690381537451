import { Profile } from './../../modelo/profile';
import { ModalService } from './../../servicios/modal.service';
import { PermisosService } from './../../servicios/permisos.service';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
// import 'datatables.net';
// import 'datatables.net-bs4';
import { default as swal } from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-perfil',
    templateUrl: './perfil.component.html',
    styleUrls: ['./perfil.component.css'],
    standalone: false
})
export class PerfilComponent implements OnInit {
  muestraTabla = false;
  loudingImg = false;
  loudingImgBusca = false;
  loudingImgCrea = false;
  loudingImgElimina = false;
  home = true;
  perf = false;
  public usuarios: [] = [];
  public procesos: {};

  perfiles = [{
    codigo: 1,
    nombre: 'Administrador'
  },
  {
    codigo: 2,
    nombre: 'Publicaciones'
  },
  {
    codigo: 3,
    nombre: 'Lectura'
  },
  {
    codigo: 4,
    nombre: 'Eo'
  }];

  estados = [{
    codigo: 1,
    nombre: 'Activo'
  },
  {
    codigo: 0,
    nombre: 'Inactivo'
  }];


  nombrePerfil = '';
  estado: any;
  perfil = '';
  id: number;
  loadingCreate = true;
  loadingTable = true;
  private usuario = JSON.parse(sessionStorage.getItem('user'));

  constructor(private translate: TranslateService, private permi: PermisosService,
    public serviModal: ModalService) {
    translate.setDefaultLang(this.usuario['_locale']);
  }


  ngOnInit() {
    this.buscarPerfiles();
  }

  onSubmit(form: NgForm) {
    console.log(form);
    if (form.value.nombre_perfil === '' || form.value.roll_perfil === '') {
      swal.fire({
        type: 'error',
        title: this.translate.instant('todos_campos'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      });
      return;
    }
    const Perfilamiento = {
      'id': '',
      'correo': form.value.nombre_perfil,
      'estado': 0,
      'creationDate': new Date(),
      'createUser': JSON.parse(sessionStorage.getItem('user'))._mail,
      'updateDate': new Date(),
      'updateUser':  JSON.parse(sessionStorage.getItem('user'))._mail,
      'idPerfilCC': form.value.roll_perfil
    };
    console.log(Perfilamiento);
    this.loudingImgCrea = true;
    this.permi.crearPerfil(Perfilamiento).subscribe((data: any) => {
      console.log(data);
      this.loudingImgCrea = false;
      if (data.perfilamientos === null || data.code === -1 || data.ok === false) {
        form.reset();
        swal.fire({
          type: 'error',
          title: this.translate.instant('error_crear_usario'),
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
        });
        return;
      }
      if (data.ok === true) {
        form.reset();
        console.log(data);
        swal.fire({
          type: 'success',
          title: this.translate.instant('usuario_creado'),
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
        });
      }
    }, (error => {
      this.loudingImgCrea = false;
      form.reset();
      swal.fire({
        type: 'error',
        title: this.translate.instant('error_crear_usario'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
      });
      return;
    }));
  }


  buscarPerfiles() {
    this.loudingImgBusca = true;
    this.permi.buscarPerfiles().subscribe((data: any) => {
      console.log(data);
      this.loudingImgBusca = false;
      if (data.perfilamientos === null || data.code === -1) {
        swal.fire({
          type: 'error',
          title: this.translate.instant('error_lista_usuarios'),
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
        });
        return;
      }
      this.usuarios = data.perfilamientos;
      this.muestraTabla = true;
    }, (error => {
      this.loudingImgBusca = false;
      swal.fire({
        type: 'error',
        title: this.translate.instant('error_lista_usuarios'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
      });
      return;
    }));
  }

  deletePerfil(id: number, correo: string, idPerfilCC: any) {
    const Perfilamiento = {
      'id': id,
      'correo': correo,
      'estado': 0,
      'creationDate': '',
      'createUser': JSON.parse(sessionStorage.getItem('user'))._mail,
      'updateDate': new Date(),
      'updateUser': new Date(),
      'idPerfilCC': idPerfilCC
    };

    Perfilamiento.correo = Perfilamiento.correo.toLowerCase();

    if (Perfilamiento.correo === JSON.parse(sessionStorage.getItem('user'))._mail) {
      swal.fire({
        type: 'error',
        title: this.translate.instant('lo_siento'),
        text: this.translate.instant('no_eliminar_mismo'),
        confirmButtonColor: '#3085d6',
      }).then((result) => { });
      return;
    }

    swal.fire({
      title: this.translate.instant('seguro'),
      text: this.translate.instant('quiere_eliminar') + Perfilamiento.correo + '?!!..',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.translate.instant('si_eliminar')
    }).then((result) => {
      if (result.value) {
        this.home = false;
        this.loudingImgElimina = true;
        this.permi.deletePerfil(Perfilamiento).subscribe((data: any) => {
          this.loudingImgElimina = false;
          this.home = true;
          if (data.ok === true) {
            this.buscarPerfiles();
            swal.fire({
              type: 'success',
              title: this.translate.instant('usuario_eliminado'),
              confirmButtonText: 'OK',
              allowOutsideClick: false
            }).then(() => {
            });
          }
        }, (error => {
          this.loudingImgElimina = false;
          this.home = true;
          swal.fire({
            type: 'error',
            title: this.translate.instant('error_elimina_usuario'),
            confirmButtonText: 'OK',
            allowOutsideClick: false
          }).then(() => {
          });
        }));
      }
    });

  }

  pasarParametros(id: number, correo: string, idPerfilCC: any, estados: any) {
    this.id = id;
    this.nombrePerfil = correo;
    this.perfil = idPerfilCC;
    this.estado = estados;

    this.nombrePerfil = this.nombrePerfil.toLowerCase();
    if (this.nombrePerfil === JSON.parse(sessionStorage.getItem('user'))._mail) {
      swal.fire({
        type: 'error',
        title: this.translate.instant('lo_siento'),
        text: this.translate.instant('no_actualizar_mismo'),
        confirmButtonColor: '#3085d6',
      }).then((result) => { });
      return;
    }
    this.serviModal.mostrarModal('', '');
  }

  cerrarModal() {
    this.serviModal.ocultarModal();
  }

  actualizar() {
    this.home = false;
    this.loudingImg = true;
    const Perfilamiento = {
      'id': this.id,
      'correo': this.nombrePerfil,
      'estado': parseInt(this.estado),
      'creationDate': new Date().toDateString(),
      'createUser': JSON.parse(sessionStorage.getItem('user'))._mail,
      'updateDate': new Date().toDateString(),
      'updateUser': JSON.parse(sessionStorage.getItem('user'))._mail,
      'idPerfilCC': parseInt(this.perfil)
    };
    this.permi.actualizarPerfil(Perfilamiento).subscribe((data: any) => {
      this.home = true;
      this.loudingImg = false;
      if (data.ok === true) {
        this.buscarPerfiles();
        this.cerrarModal();
        swal.fire({
          type: 'success',
          title: this.translate.instant('usuario_actualizado'),
          confirmButtonText: 'OK',
          allowOutsideClick: false
        }).then((result) => {
        });
      }
    }, (error => {
      this.loudingImg = false;
      this.home = true;
      swal.fire({
        type: 'error',
        title: this.translate.instant('error_actualiza_usuario'),
        confirmButtonText: 'OK',
        allowOutsideClick: false
      }).then((result) => {
      });
      return;
    }));

  }

}
