// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mouse {
    cursor: pointer;
    margin-bottom: -20px;
}

.borde {
    border: none !important;
}

a {
    transition: all .2s ease-in-out;
}

a :hover {
    transform: scale(1.1);
    color: blue;
    border-bottom: 1px solid blue;
}

.fondo {
    background-color: rgb(238, 247, 247);
}

.row .card {
    margin: 0 auto;
    /* Added */
    float: none;
    /* Added */
    margin-bottom: 10px;
    /* Added */
}

.scroll- {
    overflow-y: scroll;
    height: 300px;
    /*background:gray;*/
    padding: 5px;
}

.cursiva {
    font-family: cursive;
    font-weight: bold;
}

.tamano {
    width: 80% !important;
}

.botones {
    height: 35px !important;
}

.procesar {
    margin-top: -38px !important;
}

@media (max-width: 676px) {
    .procesar {
margin: 0 auto !important;
text-align: center;
    }
}

`, "",{"version":3,"sources":["webpack://./src/app/componentes/descomprimir/descomprimir.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,6BAA6B;AACjC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,cAAc;IACd,UAAU;IACV,WAAW;IACX,UAAU;IACV,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI;AACJ,yBAAyB;AACzB,kBAAkB;IACd;AACJ","sourcesContent":[".mouse {\n    cursor: pointer;\n    margin-bottom: -20px;\n}\n\n.borde {\n    border: none !important;\n}\n\na {\n    transition: all .2s ease-in-out;\n}\n\na :hover {\n    transform: scale(1.1);\n    color: blue;\n    border-bottom: 1px solid blue;\n}\n\n.fondo {\n    background-color: rgb(238, 247, 247);\n}\n\n.row .card {\n    margin: 0 auto;\n    /* Added */\n    float: none;\n    /* Added */\n    margin-bottom: 10px;\n    /* Added */\n}\n\n.scroll- {\n    overflow-y: scroll;\n    height: 300px;\n    /*background:gray;*/\n    padding: 5px;\n}\n\n.cursiva {\n    font-family: cursive;\n    font-weight: bold;\n}\n\n.tamano {\n    width: 80% !important;\n}\n\n.botones {\n    height: 35px !important;\n}\n\n.procesar {\n    margin-top: -38px !important;\n}\n\n@media (max-width: 676px) {\n    .procesar {\nmargin: 0 auto !important;\ntext-align: center;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
