import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { concatMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermisosService {
  configService = inject(ConfigService);
  httpOptionsAccessCors: any;

  urlPerfil = '/esignaturebff/selectPerfilCorreo/';
  urlCrearPerfil = '/esignaturebff/insertPerfil';
  urlBuscarPerfiles = '/esignaturebff/selectPerfilAll';
  urlDelete = '/esignaturebff/deletePerfil';
  urlActualizar = '/esignaturebff/updatePerfil';

  constructor(private http: HttpClient) {
  }

  permisos(email: any) {
    const correo = email;
    return this.configService.getEndpointWith$(this.urlPerfil).pipe(
      concatMap(url => this.http.get(url+correo)));
  }

  crearPerfil(Perfilamiento: any) {
    return this.configService.getEndpointWith$(this.urlCrearPerfil).pipe(
      concatMap(url => this.http.post(url, Perfilamiento)));
  }

  buscarPerfiles() {
    return this.configService.getEndpointWith$(this.urlBuscarPerfiles).pipe(
      concatMap(url => this.http.get(url)));
  }

  deletePerfil(perfilamiento: any) {
    const headers = { headers: new HttpHeaders({ 'Content-type': 'application/json' }), body: perfilamiento };
    return this.configService.getEndpointWith$(this.urlDelete).pipe(
      concatMap(url => this.http.delete(url, headers)));
  }

  actualizarPerfil(perfilamiento: any) {
    const headers = { headers: new HttpHeaders({ 'Content-type': 'application/json' }) };
    return this.configService.getEndpointWith$(this.urlActualizar).pipe(
      concatMap(url => this.http.post(url, perfilamiento, headers)));
  }
}
