import { inject, Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { getAuthConfig } from '../configuracion/auth.config';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';


@Injectable()
export class AuthService {

  readonly #configService = inject(ConfigService);

  constructor(private oAuthService: OAuthService, private router: Router) {
    this.configureWithNewConfigApi();
  }

  private configureWithNewConfigApi() {
    this.#configService.getClientId$().subscribe(clientId => {
      const authConfig = getAuthConfig(clientId);
      console.log("🚀 ~ AuthService ~ effect ~ authConfig client_id:", clientId)
      this.oAuthService.configure(authConfig);
      this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
      this.oAuthService.loadDiscoveryDocumentAndTryLogin();
    })
  };

  get identityClaims(): object {
    return this.oAuthService.getIdentityClaims();
  }

  get accessToken() {
    return this.oAuthService.getAccessToken();
  }

  login(): void {
    this.oAuthService.initImplicitFlow();
  }

  logOut(): void {
    sessionStorage.clear();
    localStorage.clear();
    // this.cookieService.deleteAll();
    this.oAuthService.logOut();
  }

  hasValidAccessToken(): boolean {
    return this.oAuthService.hasValidAccessToken();
  }

}
